import React, { useEffect, useState } from 'react';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Label,
} from 'reactstrap';
// core components
import Moment from 'moment';
import UserHeader from 'components/Headers/UserHeader.js';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import DeleteProfilePopup from 'components/Popups/DeleteProfilePopup';
import ClubService from '../../services/api/club';

const metasValues = {
    sport_speciality: ['Rugby 7', 'Rugby 13', 'Rugby 15'],
    sportive_status: ['pro', 'amateur', 'universitary'],
};

const Profile = ({ match, history }) => {
    const [club, setClub] = useState(null);
    const { id } = match.params;

    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [metas, setMetas] = useState([]);

    const [addMetaType, setAddMetaType] = useState('sport_speciality');
    const [addMetaValue, setAddMetaValue] = useState('');

    const [staffLicenceList, setStaffLicenceList] = useState([]);
    const [newStaffLicence, setNewStaffLicence] = useState(null);

    const updateUser = (name, value) => {
        setIsProfileUpdated(true);
        setClub((old) => {
            const newUser = { ...old };
            newUser[name] = value;
            return newUser;
        });
    };

    const activateClub = async () => {
        await ClubService.activateClub(club.id);
        NotificationManager.success('Club activé avec succès', '', 3000);
        ClubService.getClub(id).then(({ data }) => {
            setClub(data);
        });
    };

    const update = async (e) => {
        e.preventDefault();
        if (isProfileUpdated) {
            await ClubService.putClub(club);
            NotificationManager.success('Club enregistré avec succès', '', 3000);
            setIsProfileUpdated(false);
        }
    };

    const updateStaffLicence = () => {
        ClubService.getLicence(club?.user_id).then((res) => {
            setStaffLicenceList(res.data);
        });
    };

    const addLicence = async () => {
        const newLicence = {
            club_id: parseInt(id, 10),
            user_id: club?.user_id,
            email: newStaffLicence,
        };

        await ClubService.addLicence(newLicence);
        setNewStaffLicence('');
        updateStaffLicence(club?.id);
    };

    const removeLicence = async (licenceId) => {
        const licence = staffLicenceList.find((elem) => (elem.id === licenceId));

        await ClubService.deleteLicence(licence.id);
        updateStaffLicence(id);
    };

    const refreshMeta = () => {
        ClubService.getMetas(id).then(({ data }) => {
            setMetas(data);
        });
    };

    const onDelete = async () => {
        await ClubService.deleteClub(club.id);
        history.goBack();
    };

    const delMeta = async (meta) => {
        await ClubService.deleteMeta(meta);
        refreshMeta();
    };

    const addMeta = async () => {
        if (metas.find((item) => item.name === addMetaType)) {
            NotificationManager.error(`Il faut d'abord supprimer la meta ${addMetaType}`, '', 5000);
            return;
        }
        await ClubService.addMeta({ club_id: id, name: addMetaType, value: addMetaValue });
        refreshMeta();
    };

    const renderStaffLicence = () => {
        return (
            <CardBody className="pt-0 pt-md-4">
                <Row className="pt-0 pt-md-4">
                    <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                            <div>
                                <span className="heading">
                                    {Moment(club.date_inscription).format('DD/MM/YYYY')}
                                </span>
                                <span className="description">
                                    Inscription
                                </span>
                            </div>
                        </div>
                    </div>
                </Row>
                <Row className="mt-2">
                    <Col xl="9">
                        <Input
                            type="text"
                            value={newStaffLicence}
                            onChange={(event) => setNewStaffLicence(event.target.value)}
                        />
                    </Col>
                    <Col xl={3}>
                        <Button
                            disabled={!newStaffLicence}
                            color={newStaffLicence ? 'success' : 'warning'}
                            onClick={addLicence}
                            size="md"
                        >
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                {
                    staffLicenceList.length > 0
                    && staffLicenceList.map((value, key) => (
                        <Row key={key} className="mt-2">
                            <Col xl="9">
                                <Input
                                    type="text"
                                    value={value.email}
                                    disabled
                                />
                            </Col>
                            <Col xl={3}>
                                <Button
                                    color="danger"
                                    onClick={() => removeLicence(value.id)}
                                    size="md"
                                >
                                    <i className="fas fa-trash-alt" />
                                </Button>
                            </Col>
                        </Row>
                    ))
                }
            </CardBody>
        );
    };

    useEffect(() => {
        if (id) {
            ClubService.getClub(id).then(({ data }) => {
                setClub(data);
            });
            refreshMeta();
        }
    }, []);

    useEffect(() => {
        updateStaffLicence(id);
    }, [club]);

    if (!id || !club) {
        return (
            <>
                <UserHeader />
                <Container className="mt--3" fluid>
                    <Row>
                        <Col xs="12">
                            <Card className="card-profile shadow" style={{ minHeight: 80 }}>
                                <div className="text-center mt-4">
                                    <strong>Erreur, club non trouvé. </strong>
                                    <a href="#" onClick={() => history.goBack()}>
                                        Retour...
                                    </a>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    return (
        <>
            <UserHeader color="green"/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                        <Card className="card-profile shadow">
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <a
                                            href="#"
                                            onClick={(e) => e.preventDefault()
                                            }
                                        >
                                            <img
                                                alt="..."
                                                src={club.logo || require('../../assets/img/default_avatar.jpg')}
                                            />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            {renderStaffLicence()}
                        </Card>
                    </Col>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="6">
                                        <h3 className="mb-0">
                                            Compte Club
                                            {' '}
                                            {club.user_id && (
                                                <small>
                                                    <a
                                                        href="#"
                                                        onClick={(e) => { e.preventDefault(); history.push(`/admin/profile/${club.user_id}`); }}
                                                    >
                                                        (Utilisateur relié)
                                                    </a>
                                                </small>
                                            )}
                                        </h3>
                                    </Col>
                                    <Col className="text-right" xs="6">
                                        {!club.is_active && (
                                            <Button
                                                color="warning"
                                                onClick={activateClub}
                                                size="md"
                                            >
                                                <i className="fas fa-check" />
                                                {' '}
                                                Activer
                                            </Button>
                                        )}
                                        <Button
                                            color="primary"
                                            href="#pablo"
                                            size="md"
                                            disabled={!isProfileUpdated}
                                            onClick={update}
                                        >
                                            <i className="fas fa-save" />
                                            {' '}
                                            Sauvegarder
                                        </Button>
                                        <Button
                                            color="danger"
                                            onClick={() => setIsDeleteOpen(true)}
                                            size="md"
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <NotificationContainer/>
                                <DeleteProfilePopup
                                    visible={isDeleteOpen}
                                    onCancel={() => setIsDeleteOpen(false)}
                                    onDelete={onDelete}
                                />
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Informations générales
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-name"
                                                    >
                                                        Nom
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-name"
                                                        placeholder="Nom"
                                                        type="text"
                                                        value={club.name}
                                                        onChange={(ev) => updateUser('name', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Email du club
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        placeholder="Email"
                                                        type="text"
                                                        value={club.email}
                                                        onChange={(ev) => updateUser('email', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-president"
                                                    >
                                                        Président du club
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-president"
                                                        placeholder="Didier Lacroix"
                                                        type="email"
                                                        value={club.president}
                                                        onChange={(ev) => updateUser('president', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-dob"
                                                    >
                                                        Date création du club
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-dob"
                                                        type="date"
                                                        value={`${Moment(club.date_creation).format('YYYY-MM-DD')}`}
                                                        onChange={(ev) => updateUser('date_creation', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-phone"
                                                    >
                                                        Téléphone
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-phone"
                                                        placeholder="0606060606"
                                                        type="phone"
                                                        value={club.phone}
                                                        onChange={(ev) => updateUser('phone', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-website"
                                                    >
                                                        Site internet
                                                        {' '}
                                                        <a
                                                            href={club.website}
                                                            target="_blank"
                                                        >
                                                            <i className="fas fa-link" />
                                                        </a>
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-website"
                                                        placeholder="https://....com"
                                                        type="text"
                                                        value={club.website}
                                                        onChange={(ev) => updateUser('website', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    {/* Address */}
                                    <h6 className="heading-small text-muted mb-4">
                                        Adresse
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Ville
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-city"
                                                        placeholder="Ville"
                                                        type="text"
                                                        value={club.city}
                                                        onChange={(ev) => updateUser('city', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Adresse
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-address"
                                                        placeholder="Adresse"
                                                        type="text"
                                                        value={club.address}
                                                        onChange={(ev) => updateUser('address', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* Address */}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardBody>
                                <h6 className="heading-small text-muted mb-4">
                                    Metas
                                </h6>
                                {metas.map((meta) => (
                                    <Row className="mt-2">
                                        <Col xl={{ offset: 2, size: 3 }}>
                                            <Input type="text" value={meta.name} disabled/>
                                        </Col>
                                        <Col xl={3}>
                                            <Input type="text" value={meta.value} disabled/>
                                        </Col>
                                        <Col xl={3}>
                                            <Button
                                                color="danger"
                                                onClick={() => delMeta(meta)}
                                                size="md"
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}

                                <hr className="my-3" />

                                <Row className="mt-2">
                                    <Col xl={{ offset: 2, size: 3 }}>
                                        <Input
                                            type="select"
                                            onChange={(ev) => { setAddMetaType(ev.target.value); setAddMetaValue(metasValues[ev.target.value][0]); }}
                                        >
                                            {Object.keys(metasValues).map((key, index) => (
                                                <option value={key}>{key}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col xl={3}>
                                        <Input
                                            type="select"
                                            onChange={(ev) => setAddMetaValue(ev.target.value)}
                                        >
                                            <option value={0}>Selectionner une valeur</option>
                                            {metasValues[addMetaType].map((item) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col xl={3}>
                                        <Button
                                            color="success"
                                            onClick={addMeta}
                                            size="md"
                                        >
                                            <i className="fas fa-plus" />
                                        </Button>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Profile;
