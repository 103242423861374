import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    Row,
    Col,
    Container,
    Table,
    Button,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Alert,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import Header from '../../components/Headers/UserHeader';
import CustomPagination from '../../components/Navigation/CustomPagination';
import ClubService from '../../services/api/club';
import ClubServices from '../../services/api/club';

const User = ({ history }) => {
    const actualPag = useSelector((state) => state.pagination.clubPagination);
    const [pageCount, setPageCount] = useState(0);
    const [limit] = useState(20);
    const [filterName, setFilterName] = useState('');
    const [clubList, setClubList] = useState([]);

    const update = () => {
        const page = (actualPag || 1);

        if (filterName === '') {
            ClubService.get(limit, page).then((res) => {
                setClubList(res?.data?.data);
                setPageCount(res?.data?.pageCount);
            }).catch((e) => {
                Alert.alert('error in basic request', e);
            });
        } else {
            ClubServices.s(`{"$or": [{"name": {"$contL": "${filterName}"}}]}&page=${page}`)
                .then((res) => {
                    setClubList(res?.data);
                    setPageCount(res?.data.length / limit);
                }).catch((e) => {
                    Alert.alert('error in filtered request', e);
                });
        }
    };

    const displayRows = useCallback(() => {
        if (clubList.length > 0) {
            return clubList.map((club, key) => {
                return (
                    <tr key={key}>
                        <td className="text-center">
                            <a
                                className="ml-5"
                                href="#"
                                onClick={(e) => e.preventDefault()}
                            >
                                <img
                                    className="avatar rounded-circle"
                                    src={
                                        club.logo
                                        || require('../../assets/img/default_avatar.jpg')
                                    }
                                />
                            </a>
                        </td>
                        <td className="text-center" style={{ textTransform: 'capitalize' }}>
                            <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); history.push(`/admin/club/${club.id}`); }}
                            >
                                {`${club.name}`}
                            </a>
                        </td>
                        <td className="text-center" style={{ textTransform: 'capitalize' }}>
                            {`${club.email}`}
                        </td>
                        <td className="text-center" style={{ textTransform: 'capitalize' }}>
                            {`${club.phone}`}
                        </td>
                        <td className="text-center">
                            {
                                club.is_active
                                    ? <i className="ni text-success ni-2x ni-check-bold"/>
                                    : <i className="ni text-danger ni-3x ni-fat-remove"/>
                            }
                        </td>
                        <td className="text-center">
                            <Button
                                onClick={(e) => { e.preventDefault(); history.push(`/admin/club/${club.id}`); }}
                                color="success"
                                size="sm"
                                type="button"
                            >
                                Voir Club
                            </Button>
                        </td>
                    </tr>
                );
            });
        }
        return null;
    }, [clubList]);

    const researching = () => {
        return (
            <FormGroup className=" w-50">
                <InputGroup className="my-4">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-zoom-split-in" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Search"
                        type="text"
                        onChange={(event) => setFilterName(event.target.value) }
                    />
                </InputGroup>
            </FormGroup>
        );
    };

    useEffect(() => {
        update();
    }, [actualPag, filterName]);

    return (
        <>
            <Header color="yellow" />
            <Container className="mt--7" fluid>
                <Card
                    className="bg-secondary shadow border-0"
                    style={{ marginTop: 80 }}
                >
                    <Row>
                        <Col xs={{ size: 6, offset: 5 }}>
                            {researching()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope={'col'}></th>
                                        <th scope={'col'} className="text-center">Club</th>
                                        <th scope={'col'} className="text-center">Email</th>
                                        <th scope={'col'} className="text-center">Téléphone</th>
                                        <th scope={'col'} className="text-center">Certifié</th>
                                        <th scope={'col'} className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayRows()}
                                </tbody>
                            </Table>
                            <CustomPagination
                                actualPagination={actualPag}
                                pageCount={pageCount}
                                paginationType='club'
                            />
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
};

export default User;
