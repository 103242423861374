import React, { useEffect, useState } from 'react';
// node.js library that concatenates classes (strings)
import classnames from 'classnames';
// javascipt plugin for creating charts
import Chart from 'chart.js';
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
} from 'reactstrap';

import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
} from '../variables/charts';

import Header from '../components/Headers/Header';

const Index = () => {
    const [activeNav, setActiveNav] = useState(1);
    const [chartExample1Data, setChartExample1Data] = useState('data1');

    const toggleNavs = (e, index) => {
        e.preventDefault();
        setActiveNav(index);
        setChartExample1Data(chartExample1Data === 'data1' ? 'data2' : 'data1');
    };

    useEffect(() => {
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
    }, []);

    return (
        <>
            <Header />
        </>
    );
};

export default Index;
