import axios from 'axios';
import { store } from '../../store/store';

import { API_URL } from '../../config.json';

axios.interceptors.request.use(async (config) => {
    const { user } = store.getState();

    if (user.token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
});

export async function fetch(endpoint, method = 'get', data = {}, headers = {}) {
    const url = API_URL + endpoint;

    const opts = {
        url,
        method,
    };

    if (method !== 'get') {
        opts.data = data;
        opts.headers = headers;
    }

    return axios(opts);
}

export default {};
