import React from 'react';
import { Provider } from 'react-redux';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import './assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';

import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';
import { persistor, store } from './store/store';

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Switch>
                        <Route
                            path="/admin"
                            render={(props) => <AdminLayout {...props} />}
                        />
                        <Route
                            path="/auth"
                            render={(props) => <AuthLayout {...props} />}
                        />
                        <Redirect from="/" to="/admin/index"/>
                    </Switch>
                </BrowserRouter>
            </PersistGate>
        </Provider>);
};

export default App;
