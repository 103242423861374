import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    Row,
    Col,
    Container,
    Table,
    Button,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Alert,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import Header from '../../components/Headers/UserHeader';
import UserServices from '../../services/api/user';
import CustomPagination from '../../components/Navigation/CustomPagination';

const User = ({ history }) => {
    const actualPag = useSelector((state) => state.pagination.userPagination);
    const [pageCount, setPageCount] = useState(0);
    const [limit, setLimit] = useState(20);
    const [filterName, setFilterName] = useState('');
    const [userList, setUserList] = useState([]);

    const update = () => {
        const page = (actualPag || 1);

        if (filterName === '') {
            UserServices.get(limit, page, null).then((res) => {
                setUserList(res?.data?.data);
                setPageCount(res?.data?.pageCount);
            }).catch((e) => {
                Alert.alert('Error fetch message', e);
            });
        } else {
            UserServices.s(`{"$or": [{"first_name": {"$contL": "${filterName}"}}, {"last_name": { "$contL":"${filterName}"}},{"email": { "$contL":"${filterName}"}}]}&page=${page}`)
                .then((res) => {
                    setUserList(res?.data);
                    setPageCount(res?.data.length / limit);
                }).catch((e) => {
                    Alert.alert('Error fetch message', e);
                });
        }
    };

    const displayRows = useCallback(() => {
        if (userList.length > 0) {
            return userList.map((user, key) => {
                return (
                    <tr key={key}>
                        <td className="text-center">
                            <a
                                className="ml-5"
                                href="#"
                                onClick={(e) => e.preventDefault()}
                            >
                                <img
                                    className="avatar rounded-circle"
                                    src={
                                        user.avatar
                                        || require('../../assets/img/default_avatar.jpg')
                                    }
                                />
                            </a>
                        </td>
                        <td className="text-center" style={{ textTransform: 'capitalize' }}>
                            <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); history.push(`/admin/profile/${user.id}`); }}
                            >
                                {`${user.first_name} ${user.last_name}`}
                            </a>
                        </td>
                        <td className="text-center">
                            {user.fk_type_id === 1 ? 'Joueur' : 'Club'}
                        </td>
                        <td className="text-center">
                            {user.email}
                        </td>
                        <td className="text-center">
                            {user.gender}
                        </td>
                        <td className="text-center">
                            {user.country
                                ? <img
                                    alt={user.country}
                                    src={`https://www.countryflags.io/${user.country}/flat/32.png`}
                                />
                                : 'inconnu'
                            }
                        </td>
                        <td className="text-center">
                            {
                                user.is_certified
                                    ? <i className="ni text-success ni-2x ni-check-bold"/>
                                    : <i className="ni text-danger ni-3x ni-fat-remove"/>
                            }
                        </td>
                        <td className="text-center">
                            <Button
                                onClick={(e) => { e.preventDefault(); history.push(`/admin/profile/${user.id}`); }}
                                color="success"
                                size="sm"
                                type="button"
                            >
                            Voir Profil
                            </Button>
                        </td>
                    </tr>
                );
            });
        }
        return null;
    }, [userList]);

    const researching = () => {
        return (
            <FormGroup className=" w-50">
                <InputGroup className="my-4">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-zoom-split-in" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Search"
                        type="text"
                        onChange={(event) => setFilterName(event.target.value) }
                    />
                </InputGroup>
            </FormGroup>
        );
    };

    useEffect(() => {
        update();
    }, [actualPag, filterName]);

    return (
        <>
            <Header color="yellow" />
            <Container className="mt--7" fluid>
                <Card
                    className="bg-secondary shadow border-0"
                    style={{ marginTop: 80 }}
                >
                    <Row>
                        <Col xs={{ size: 6, offset: 5 }}>
                            {researching()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope={'col'}></th>
                                        <th scope={'col'} className="text-center">Utilisateur</th>
                                        <th scope={'col'} className="text-center">Type</th>
                                        <th scope={'col'} className="text-center">Email</th>
                                        <th scope={'col'} className="text-center">Genre</th>
                                        <th scope={'col'} className="text-center">Pays</th>
                                        <th scope={'col'} className="text-center">Certifié</th>
                                        <th scope={'col'} className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayRows()}
                                </tbody>
                            </Table>
                            <CustomPagination
                                actualPagination={actualPag}
                                pageCount={pageCount}
                                paginationType='user'
                            />
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
};

export default User;
