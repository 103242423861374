import types from './types';

const PaginationActions = {};

PaginationActions.setUserPagination = (pagination) => ({
    type: types.SET_USER_PAGINATION,
    payload: pagination,
});

PaginationActions.setClubPagination = (pagination) => ({
    type: types.SET_CLUB_PAGINATION,
    payload: pagination,
});

export default PaginationActions;
