import React from 'react';
import {
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import pagination from '../../store/actions/pagination';

const CustomPagination = ({
    actualPagination,
    paginationType,
    pageCount,
}) => {
    const dispatch = useDispatch();

    const prevPage = () => {
        if (actualPagination > 1) {
            if (paginationType === 'user') {
                dispatch(pagination.setUserPagination(actualPagination - 1));
            } else {
                dispatch(pagination.setClubPagination(actualPagination - 1));
            }
        }
        return null;
    };

    const nextPage = () => {
        if (actualPagination < pageCount) {
            if (paginationType === 'user') {
                dispatch(pagination.setUserPagination(actualPagination + 1));
            } else {
                dispatch(pagination.setClubPagination(actualPagination + 1));
            }
        }
        return null;
    };

    const changePage = (choice) => {
        if (actualPagination !== choice) {
            if (paginationType === 'user') {
                dispatch(pagination.setUserPagination(choice));
            } else {
                dispatch(pagination.setClubPagination(choice));
            }
        }
        return null;
    };

    const computeactualPagination = () => {
        if (actualPagination === 1) {
            return (2);
        }
        if (actualPagination === pageCount) {
            return (pageCount - 1);
        }
        return (actualPagination);
    };

    return (
        <>
            <Pagination
                className="justify-content-center pagination-success"
                listClassName="justify-content-center pagination-success"
            >
                <PaginationItem>
                    <PaginationLink onClick={() => changePage(1)}>
                        <i className="fa fa-angle-double-left"/>
                        <span className="sr-only">
                            Next
                        </span>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem className="mr-4">
                    <PaginationLink
                        onClick={prevPage}
                        tabIndex="-1"
                    >
                        <i className="fa fa-angle-left" />
                        <span className="sr-only">
                            Previous
                        </span>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem className={actualPagination === 1 ? 'active' : 'disabled'}>
                    <PaginationLink>
                        1
                    </PaginationLink>
                </PaginationItem>
                {pageCount >= 3
                && <PaginationItem
                    className={
                        actualPagination !== 1 && actualPagination !== pageCount ? 'active' : 'disabled'
                    }>
                    <PaginationLink>
                        {computeactualPagination()}
                    </PaginationLink>
                </PaginationItem>
                }
                {pageCount >= 2
                && <PaginationItem
                    className={actualPagination === pageCount ? 'active' : 'disabled'}>
                    <PaginationLink>
                        {pageCount}
                    </PaginationLink>
                </PaginationItem>
                }
                <PaginationItem className="ml-4">
                    <PaginationLink onClick={nextPage}>
                        <i className="fa fa-angle-right" />
                        <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink onClick={() => changePage(pageCount)}>
                        <i className="fa fa-angle-double-right"/>
                        <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </>
    );
};

CustomPagination.propTypes = {
    actualPagination: PropTypes.number.isRequired,
    paginationType: PropTypes.string.isRequired,
    pageCount: PropTypes.number.isRequired,
};

export default CustomPagination;
