import React, { useState } from 'react';

// reactstrap components
import {
    Row,
    Col,
    Card,
    Button,
    CardBody,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Alert,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../services/api/auth';
import UserActions from '../../store/actions/user';
// core components

const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [oauthLoading, setOauthLoading] = useState(false);

    const dispatch = useDispatch();

    const login = () => {
        // TODO: set spinner
        setOauthLoading(true);
        AuthService.login(email, password)
            .then((res) => {
                if (res?.data?.token === null) {
                    throw (new Error('No token generated'));
                }
                dispatch(UserActions.setToken(res?.data.token));
                setOauthLoading(false);
                props.history.push('/admin/index');
            }).catch((e) => {
                Alert.alert('Error request token', e);
                setOauthLoading(false);
            });
    };

    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="new-email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Mot de passe"
                                        type="password"
                                        autoComplete="new-password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                                <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    onClick={login}
                                >
                                    Connexion
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default Login;
