import { fetch } from './api';

const UserServices = {};

UserServices.get = (
    limit,
    page,
) => {
    return (fetch(`/user?limit=${20}&page=${page}&sort=id,DESC`));
};

UserServices.getUser = (id) => {
    return (fetch(`/user/${id}`, 'GET'));
};

UserServices.s = (s = '') => {
    return fetch(`/user?s=${s}`);
};

UserServices.getByType = (type = 1) => {
    return (fetch(`/user?limit=2&page=0&filter=fk_type_id||$eq||${type}`));
};

UserServices.putPassword = (id, password) => {
    return fetch('/user/putpassword', 'PUT', { id, password });
};

UserServices.putUser = (user) => {
    return fetch('/user/putuser', 'PUT', { user });
};

UserServices.directCertify = (id) => {
    return fetch('/certification/directCertify', 'POST', { id });
};

UserServices.deleteUser = (id) => {
    return fetch('/user/deleteuser', 'DELETE', { id });
};

export default UserServices;
