import Index from './views/Index';
import User from './views/user';
import Clubs from './views/clubs';
import NewClubs from './views/newClubs';
import Certif from './views/certifications';
import Login from './views/login';
import Tables from './views/examples/Tables';
import Profile from './views/profile';
import ClubProfile from './views/clubProfile';
import Notification from './views/notifications';
import Report from './views/report';

const routes = [
    {
        path: '/index',
        name: 'Dashboard',
        icon: 'ni ni-tv-2 text-primary',
        component: Index,
        layout: '/admin',
        isOnSidebar: true,
    },
    {
        path: '/users',
        name: 'Utilisateurs',
        icon: 'ni ni-circle-08 text-yellow',
        component: User,
        layout: '/admin',
        isOnSidebar: true,
    },
    {
        path: '/clubs',
        name: 'Clubs',
        icon: 'ni ni-badge text-green',
        component: Clubs,
        layout: '/admin',
        isOnSidebar: true,
    },
    {
        path: '/newclubs',
        name: 'Clubs à activer',
        icon: 'ni ni-lock-circle-open text-danger',
        component: NewClubs,
        layout: '/admin',
        isOnSidebar: true,
    },
    {
        path: '/clubs',
        name: 'Clubs',
        icon: 'ni ni-badge text-green',
        component: Tables,
        layout: '/admin',
        isOnSidebar: false,
    },
    {
        path: '/login',
        name: 'Login',
        icon: 'ni ni-badge text-green',
        component: Login,
        layout: '/auth',
        isOnSidebar: false,
    },
    {
        path: '/certifications',
        name: 'certifications',
        icon: 'ni ni-diamond text-blue',
        component: Certif,
        layout: '/admin',
        isOnSidebar: true,
    },
    {
        path: '/icons',
        name: 'Signalements',
        icon: 'ni ni-notification-70 text-red',
        component: Report,
        layout: '/admin',
        isOnSidebar: true,
    },
    {
        path: '/notification',
        name: 'Notification',
        icon: 'ni ni-notification-70 text-red',
        component: Notification,
        layout: '/admin',
        isOnSidebar: false,
    },
    {
        path: '/profile/:id',
        layout: '/admin',
        component: Profile,
        isOnSidebar: false,
    },
    {
        path: '/club/:id',
        layout: '/admin',
        component: ClubProfile,
        isOnSidebar: false,
    },
];
export default routes;
