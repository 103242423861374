import jwtDecode from 'jwt-decode';
import ActionTypes from '../actions/types';

const initialState = {
    user: null,
    token: '',
};

const setToken = (state, action) => {
    try {
        const decoded = jwtDecode(action.payload);

        return {
            user: decoded,
            token: action.payload,
        };
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return state;
    }
};

const userAction = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_TOKEN:
            return setToken(state, action);
        default:
            return state;
    }
};

export default userAction;
