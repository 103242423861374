import React from 'react';

import {
  Button,
  Modal,
} from "reactstrap";

const DeleteProfilePopup = ({ visible, onDelete, onCancel }) => {
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={visible}
      >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={onCancel}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <strong>Voulez-vous vraiment supprimer ce compte ?</strong>
            <br />
            Cette action est irréversible.
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={onCancel}
            >
              Annuler
            </Button>
            <Button
              color="danger"
              type="button"
              onClick={onDelete}
            >
              Oui, Supprimer
            </Button>
          </div>
      </Modal>
    );
};

export default DeleteProfilePopup;
