/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';

// reactstrap components
import {
    Card, CardBody, CardTitle, Container, Row, Col,
} from 'reactstrap';

import UserService from '../../services/api/user';
import ClubService from '../../services/api/club';

const Header = () => {
    const [users, setUsers] = useState({ today: [], yesterday: [] });
    const [players, setPlayers] = useState(0);
    const [clubs, setClubs] = useState(0);
    const [totalClubs, setTotalClubs] = useState(0);

    useEffect(() => {
        const d = new Date();
        UserService.s(`{"date_creation": {"$gte": "${d.getFullYear()}-${(`0${d.getMonth() + 1}`).slice(-2)}-${(`0${d.getDate()}`).slice(-2)}T00:00:00"}}`).then(({ data }) => {
            setUsers((old) => {
                const newUsers = { ...old };
                newUsers.today = data;
                return newUsers;
            });
        });

        const yd = new Date();
        yd.setDate(yd.getDate() - 1);
        UserService.s(`{"$and": [{"date_creation": {"$gte": "${yd.getFullYear()}-${(`0${yd.getMonth() + 1}`).slice(-2)}-${(`0${yd.getDate()}`).slice(-2)}T00:00:00"}},{"date_creation": {"$lt": "${d.getFullYear()}-${(`0${d.getMonth() + 1}`).slice(-2)}-${(`0${d.getDate()}`).slice(-2)}T00:00:00"}}]}`).then(({ data }) => {
            setUsers((old) => {
                const newUsers = { ...old };
                newUsers.yesterday = data;
                return newUsers;
            });
        });

        UserService.getByType(1).then(({ data }) => {
            setPlayers(data.total);
        });

        UserService.getByType(2).then(({ data }) => {
            setClubs(data.total);
        });

        ClubService.get(2, 0).then(({ data }) => {
            setTotalClubs(data.total);
        });
    }, []);

    return (
        <>
            <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                        {/* Card stats */}
                        <Row>
                            <Col lg="6" xl="3">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Nouveaux utilisateurs
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {users.today.length}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                    <i className="ni ni-single-02" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-nowrap">
                                                Aujourd'hui

                                            </span>{' '}
                                            <span className={`text-${users.today.length >= users.yesterday.length ? 'success' : 'danger'} mr-2`}>
                                                <i className={`fas fa-arrow-${users.today.length >= users.yesterday.length ? 'up' : 'down'}`} />{' '}
                                                {users.today.length - users.yesterday.length >= 0 ? '+' : ''}{users.today.length - users.yesterday.length} par rapport à hier
                                            </span>{' '}
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Total de joueurs
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {players}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-pie" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-nowrap">
                                                {' '}
                                            </span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Total de clubs actifs
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    <strong>{clubs}</strong> <small>/ {totalClubs}</small>
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                                    <i className="ni ni-badge" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Premiums
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    N/A
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                                    <i className="ni ni-money-coins" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Header;
