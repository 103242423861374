import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import { useSelector } from 'react-redux';
import routes from '../routes';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import AdminFooter from '../components/Footers/AdminFooter';
import Sidebar from '../components/Sidebar/Sidebar';

const Admin = (props) => {
    const reduxState = useSelector((state) => state.user);
    const oauthBool = reduxState && reduxState.user && reduxState.user.token !== '';

    const getRoutes = (routes) => routes.map((prop, key) => {
        if (prop.layout === '/admin') {
            return (
                <Route
                    path={prop.layout + prop.path}
                    render={(routeProps) => (oauthBool === true
                        ? <prop.component {...routeProps}/>
                        : <Redirect to="/auth/login"/>)}
                    key={key}
                />
            );
        }
        return null;
    });

    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (
                props.location.pathname.indexOf(
                    routes[i].layout + routes[i].path,
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return 'Brand';
    };

    return (
        <>
            <Sidebar
                {...props}
                routes={routes}
                logo={{
                    innerLink: '/admin/index',
                    imgSrc: require('../assets/img/brand/logo_color.png'),
                    imgAlt: 'myRookie',
                }}
            />
            <div className="main-content">
                <AdminNavbar
                    {...props}
                    brandText={getBrandText(props.location.pathname)}
                />
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="*" to="/admin/index" />
                </Switch>
                <Container fluid>
                    <AdminFooter />
                </Container>
            </div>
        </>
    );
};

export default Admin;
