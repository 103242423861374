import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    Row,
    Col,
    Container,
    Table,
    Button,
} from 'reactstrap';
import Header from '../../components/Headers/UserHeader';
import ClubService from '../../services/api/club';

const User = ({ history }) => {
    const [clubList, setClubList] = useState([]);

    const update = () => {
        ClubService.s('{"$and": [{"is_active": false}, {"user_id": {"$notnull": true}}]}').then(({ data }) => {
            setClubList(data);
        }).catch((e) => {
            alert(e);
        });
    };

    const displayRows = useCallback(() => {
        if (clubList.length > 0) {
            return clubList.map((club, key) => {
                return (
                    <tr key={key}>
                        <td className="text-center">
                            <a
                                className="ml-5"
                                href="#"
                                onClick={(e) => e.preventDefault()}
                            >
                                <img
                                    className="avatar rounded-circle"
                                    src={
                                        club.logo
                                        || require('../../assets/img/default_avatar.jpg')
                                    }
                                />
                            </a>
                        </td>
                        <td className="text-center" style={{ textTransform: 'capitalize' }}>
                            <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); history.push(`/admin/club/${club.id}`); }}
                            >
                                {`${club.name}`}
                            </a>
                        </td>
                        <td className="text-center" style={{ textTransform: 'capitalize' }}>
                            {`${club.email}`}
                        </td>
                        <td className="text-center" style={{ textTransform: 'capitalize' }}>
                            {`${club.phone}`}
                        </td>
                        <td className="text-center">
                            <Button
                                onClick={(e) => { e.preventDefault(); history.push(`/admin/club/${club.id}`); }}
                                color="success"
                                size="sm"
                                type="button"
                            >
                            Voir Club
                            </Button>
                        </td>
                    </tr>
                );
            });
        }
        return null;
    }, [clubList]);

    useEffect(() => {
        update();
    }, []);

    return (
        <>
            <Header color="danger" />
            <Container className="mt--7" fluid>
                <Card
                    className="bg-secondary shadow border-0"
                    style={{ marginTop: 80 }}
                >
                    <Row>
                        <Col xs={12}>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope={'col'}></th>
                                        <th scope={'col'} className="text-center">Nom</th>
                                        <th scope={'col'} className="text-center">Email</th>
                                        <th scope={'col'} className="text-center">Tél</th>
                                        <th scope={'col'} className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayRows()}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
};

export default User;
