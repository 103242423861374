import React, { useState } from "react";

import {
    Row,
    Col,
    Button,
} from "reactstrap";

import Moment from 'moment';

import CertificationService from '../../services/api/certifications';

const CertifModal = ({ certif, close, update }) => {
    if (!certif) return null;

    const isClub = certif.user.fk_type_id === 2;
    const avatar = isClub ? certif.club?.logo : certif.user.avatar;

    const certify = (action) => {
        CertificationService.certify(certif.id, action)
        .then(() => {
            update();
            close();
        })
        .catch((e) => alert(e));
    }

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Certification
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={close}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col xs="4" className="bg-secondary" style={{ paddingTop: 25 }}>
                        <div className="text-center">
                            <img
                                className="avatar rounded-circle"
                                src={
                                    avatar ||
                                    require("../../assets/img/default_avatar.jpg")
                                }
                                style={{ width: 100, height: 100 }}
                            />
                        </div>
                    </Col>
                    <Col xs="8">
                        <b style={{ textTransform: 'capitalize' }}>{certif.user.first_name} {certif.user.last_name}</b>
                        {isClub && (
                            <b style={{ textTransform: 'capitalize' }}>{certif.club.name}</b>
                        )}
                        <p>
                            <b>Date de naissance: </b>
                            {Moment(certif.user.date_of_birth).format('DD/MM/YYYY')}
                            {' '}
                            ({Moment().diff(certif.user.date_of_birth, 'years')} ans)
                        </p>
                        <p>
                            <b>Nationalité: </b>
                            {certif.user.country}
                        </p>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={() => {}}
                        >
                            Voir le profil
                        </Button>
                        <Button
                            color="success"
                            type="button"
                            onClick={() => certify('accept')}
                        >
                            Certifier
                        </Button>
                        <Button
                            onClick={() => certify('reject')}
                            color="danger"
                            type="button"
                        >
                            Refuser
                        </Button>
                    </Col>
                </Row>
                <hr className="my-3" />
                <Row>
                    <Col xs="12">
                        {isClub && (
                            <h2>Statut du club</h2>
                        )}
                        {!isClub && (
                            <h2>License sportive</h2>
                        )}
                        <img
                            src={certif.step1}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        {isClub && (
                            <h2>ID responsable</h2>
                        )}
                        {!isClub && (
                            <h2>Passeport / Carte d&apos;identité</h2>
                        )}
                        <img
                            src={certif.step2}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <h2>Selfie</h2>
                        <img
                            src={certif.step3}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={close}
                >
                    Fermer
                </Button>
                <Button
                    color="success"
                    type="button"
                    onClick={() => certify('accept')}
                >
                    Certifier
                </Button>
                <Button
                    onClick={() => certify('reject')}
                    color="danger"
                    type="button"
                >
                    Refuser
                </Button>
            </div>
        </div>
    );
};

export default CertifModal;
