import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    Row,
    Col,
    Container,
    Table,
    Button,
} from 'reactstrap';
import Header from '../../components/Headers/UserHeader';
import ReportServices from '../../services/api/report';

const Report = ({ history }) => {
    const [reportList, setReportList] = useState([]);

    const redirectToReportedSubject = (type, data) => {
        switch (type) {
            case 'post':
                window.location.replace(`https://myrookie.io/fr/media/${data.content_id}`);
                break;
            case 'user':
                history.push(`/admin/profile/${data.content_id}`);
                break;
            case 'offer':
                history.push(`/admin/profile/${data.content_id}`);
                break;
            default:
                return null;
        }
        return null;
    };

    const update = () => {
        ReportServices.get().then(({ data }) => {
            setReportList(data);
        }).catch((e) => {
            alert(e);
        });
    };

    const displayRows = useCallback(() => {
        if (reportList.length > 0) {
            return reportList.map((report, key) => {
                return (
                    <tr key={key}>
                        <td className="text-center" style={{ textTransform: 'capitalize' }}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    redirectToReportedSubject(report.type, report);
                                }}
                            >
                                {`${report.type}`}
                            </a>
                        </td>
                        <td className="text-center" style={{ textTransform: 'capitalize' }}>
                            {`${report.description}`}
                        </td>
                        <td className="text-center">
                            <Button
                                onClick={(e) => { e.preventDefault(); history.push(`/admin/profile/${report.fk_user_id}`); }}
                                color="success"
                                size="sm"
                                type="button"
                            >
                                Emetteur du rapport
                            </Button>
                            <Button
                                onClick={(e) => { e.preventDefault(); history.push(`/admin/profile/${report.content_id}`); }}
                                color="primary"
                                size="sm"
                                type="button"
                            >
                                Cible du rapport
                            </Button>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    ReportServices.delete(report.id).then(() => {
                                        update();
                                    });
                                }}
                                color="danger"
                                size="sm"
                                type="button"
                            >
                                Supprimer le rapport
                            </Button>
                        </td>
                    </tr>
                );
            });
        }
        return null;
    }, [reportList]);

    useEffect(() => {
        update();
    }, []);

    return (
        <>
            <Header color="danger" />
            <Container className="mt--7" fluid>
                <Card
                    className="bg-secondary shadow border-0"
                    style={{ marginTop: 80 }}
                >
                    <Row>
                        <Col xs={12}>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope={'col'} className="text-center">Type</th>
                                        <th scope={'col'} className="text-center">Catégorie</th>
                                        <th scope={'col'} className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayRows()}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
};

export default Report;
