import { fetch } from './api';

const ReportServices = {};

ReportServices.get = () => {
    return (fetch('/report'));
};

ReportServices.delete = (id) => {
    return fetch(`/report/${id}`, 'DELETE');
};

export default ReportServices;
