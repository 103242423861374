import ActionTypes from '../actions/types';

const initialState = {
    userPagination: 1,
    clubPagination: 1,
};

const setUserPagination = (state, action) => {
    return {
        ...state,
        userPagination: action.payload,
    };
};

const setClubPagination = (state, action) => {
    return {
        ...state,
        clubPagination: action.payload,
    };
};

const paginationAction = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_USER_PAGINATION:
            return setUserPagination(state, action);
        case ActionTypes.SET_CLUB_PAGINATION:
            return setClubPagination(state, action);
        default:
            return state;
    }
};

export default paginationAction;
