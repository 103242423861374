/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import Moment from 'moment';
import UserHeader from "components/Headers/UserHeader.js";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import UserService from '../../services/api/user';

import Countries from '../../assets/iso';
import DeleteProfilePopup from "components/Popups/DeleteProfilePopup";
import ClubService from "services/api/club";
const userTypes = ['', 'joueur', 'club non relié'];

const Profile = ({ match, history }) => {
    const [user, setUser] = useState(null);
    const { id } = match.params;

    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [password, setPassword] = useState('');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [club, setClub] = useState(null);

    const generatePassword = () => {
        setPassword(Math.random().toString(36).substring(6));
    };

    const updateUser = (name, value) => {
        setIsProfileUpdated(true);
        setUser((old) => {
            const newUser = { ...old };
            newUser[name] = value;
            return newUser;
        });
    };

    const saveProfile = async (e) => {
        e.preventDefault();
        if (isProfileUpdated) {
            await UserService.putUser(user);
        }
        if (password.length) {
            await UserService.putPassword(user.id, password);
        }
        NotificationManager.success('Enregistré avec succès', '', 3000);
    };

    const certify = async (e) => {
        e.preventDefault();
        await UserService.directCertify(user.id);
        NotificationManager.success('Utilisateur certifié', '', 3000);
        setUser((old) =>{
            const newUser = { ...old };
            newUser.is_certified = true;
            return newUser;
        })
    };

    const onDelete = async () => {
        await UserService.deleteUser(user.id);
        history.goBack();
    };

    useEffect(() => {
        if (id) {
            UserService.getUser(id).then(({ data }) => {
                setUser(data);
                if (data.fk_type_id === 2) {
                    ClubService.s(`{"user_id": ${data.id}}`).then(({ data }) => {
                        if (data.length) {
                            setClub(data[0]);
                        }
                    });
                }
            });
        }
    }, []);

    if (!id || !user) {
        return (
            <>
                <UserHeader />
                <Container className="mt--3" fluid>
                    <Row>
                        <Col xs="12">
                            <Card className="card-profile shadow" style={{ minHeight: 80 }}>
                                <div className="text-center mt-4">
                                    <strong>Erreur, utilisateur non trouvé. </strong>
                                    <a href="#" onClick={() => history.goBack()}>
                                        Retour...
                                    </a>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    return (
        <>
            <UserHeader color="yellow" />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                        <Card className="card-profile shadow">
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <a
                                            href="#"
                                            onClick={(e) =>
                                                e.preventDefault()
                                            }
                                        >
                                            <img
                                                alt="..."
                                                src={user.avatar || require('../../assets/img/default_avatar.jpg')}
                                            />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <CardBody className="pt-0 pt-md-4">
                                <Row>
                                    <div className="col">
                                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                            <div>
                                                <span className="heading">
                                                    {club && (
                                                        <a
                                                            href="#"
                                                            onClick={(e) => { e.preventDefault(); history.push(`/admin/club/${club.id}`); }}
                                                        >
                                                            Club relié
                                                        </a>
                                                    )}
                                                    {!club && (
                                                        userTypes[user.fk_type_id]
                                                    )}
                                                </span>
                                                <span className="description">
                                                    Profile
                                                </span>
                                            </div>
                                            <div>
                                                <span className="heading">
                                                    {user.country}
                                                </span>
                                                <span className="description">
                                                    Pays
                                                </span>
                                            </div>
                                            <div>
                                                <span className="heading">
                                                    {Moment(user.date_creation).format('DD/MM/YYYY')}
                                                </span>
                                                <span className="description">
                                                    Inscription
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <div className="text-center">
                                    <h3 style={{ textTransform: 'capitalize' }}>
                                        {user.first_name} {user.last_name}
                                        <span className="font-weight-light">
                                            , {Moment().diff(user.date_of_birth, 'years')} ans
                                        </span>
                                    </h3>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2" />
                                        {user.address}, {user.city}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="6">
                                        <h3 className="mb-0">
                                            Compte utilisateur {" "}
                                            <small style={{ textTransform: "capitalize" }}>
                                                (
                                                    {club && (
                                                        <a
                                                            href="#"
                                                            onClick={(e) => { e.preventDefault(); history.push(`/admin/club/${club.id}`); }}
                                                        >
                                                            {club.name}
                                                        </a>
                                                    )}
                                                    {!club && (
                                                        userTypes[user.fk_type_id]
                                                    )}
                                                )
                                            </small>
                                        </h3>
                                    </Col>
                                    <Col className="text-right" xs="6">
                                        {!user.is_certified && (
                                            <Button
                                                color="success"
                                                href="#pablo"
                                                size="md"
                                                onClick={certify}
                                            >
                                                <i className="fas fa-check-circle" />
                                                {" "}
                                                Certif
                                            </Button>
                                        )}
                                        <Button
                                            color="primary"
                                            href="#pablo"
                                            onClick={saveProfile}
                                            size="md"
                                            disabled={!(isProfileUpdated || password.length)}
                                        >
                                            <i className="fas fa-save" />
                                            {" "}
                                            Sauvegarder
                                        </Button>
                                        <Button
                                            color="danger"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIsDeleteOpen(true);
                                            }}
                                            size="md"
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <NotificationContainer/>
                                <DeleteProfilePopup
                                    visible={isDeleteOpen}
                                    onCancel={() => setIsDeleteOpen(false)}
                                    onDelete={onDelete}
                                />
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Informations générales
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Prénom
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        placeholder="Prénom"
                                                        type="text"
                                                        value={user.first_name}
                                                        onChange={(ev) => updateUser('first_name', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Nom
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-first-name"
                                                        placeholder="Nom"
                                                        type="text"
                                                        value={user.last_name}
                                                        onChange={(ev) => updateUser('last_name', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Email
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        placeholder="eric@myrookie.io"
                                                        type="email"
                                                        value={user.email}
                                                        onChange={(ev) => updateUser('email', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Mot de passe
                                                    </label>
                                                    <InputGroup>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-last-name"
                                                            placeholder="Modifier mot de passe"
                                                            type="text"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                            <Button
                                                                color="secondary"
                                                                onClick={generatePassword}
                                                            >
                                                                <i className="fas fa-random" alt="Générer" />
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-dob"
                                                    >
                                                        Date de naissance
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-dob"
                                                        type="date"
                                                        value={`${Moment(user.date_of_birth).format('YYYY-MM-DD')}`}
                                                        onChange={(ev) => updateUser('date_of_birth', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-gender"
                                                    >
                                                        Genre
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-dob"
                                                        type="select"
                                                        onChange={(ev) => updateUser('gender', ev.target.value)}
                                                    >
                                                        <option value="male" selected={user.gender === 'male'}>Male</option>
                                                        <option value="female" selected={user.gender === 'female'}>Female</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    {/* Address */}
                                    <h6 className="heading-small text-muted mb-4">
                                        Informations du compte
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Pays
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue="FR"
                                                        id="input-country"
                                                        placeholder="Pays"
                                                        type="select"
                                                        onChange={(ev) => updateUser('country', ev.target.value)}
                                                    >
                                                        {Countries.map((item) => (
                                                            <option value={item.code} selected={item.code === user.country}>{item.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Ville
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-city"
                                                        placeholder="Ville"
                                                        type="text"
                                                        value={user.city}
                                                        onChange={(ev) => updateUser('city', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Adresse
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-address"
                                                        placeholder="Adresse"
                                                        type="text"
                                                        value={user.address}
                                                        onChange={(ev) => updateUser('address', ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Profile;
