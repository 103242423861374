const ActionTypes = {
    // Testing
    TEST: 'TEST',
    // User
    SET_TOKEN: 'SET_TOKEN',
    // Pagination
    SET_USER_PAGINATION: 'SET_USER_PAGINATION',
    SET_CLUB_PAGINATION: 'SET_CLUB_PAGINATION',
};

export default ActionTypes;
