import { fetch } from './api';

const ClubService = {};

ClubService.get = (limit, page) => {
    return fetch(`/club?limit=${20}&page=${page}&sort=id,DESC`);
};

ClubService.getClub = (id) => {
    return fetch(`/club/${id}`);
};

ClubService.putClub = (club) => {
    return fetch('/club/putclub', 'PUT', { club });
};

ClubService.s = (s = '') => {
    return fetch(`/club?s=${s}`);
};

ClubService.deleteClub = (id) => {
    return fetch('/club/deleteclub', 'DELETE', { id });
};

ClubService.getMetas = (id) => {
    return fetch(`/club-meta?filter=club_id||$eq||${id}`);
};

ClubService.addMeta = (clubMeta) => {
    return fetch('/club-meta/addmeta', 'POST', { clubMeta });
};

ClubService.deleteMeta = (clubMeta) => {
    return fetch('/club-meta/delmeta', 'DELETE', { clubMeta });
};

ClubService.activateClub = (id) => {
    return fetch('/club/activateClub', 'POST', { id });
};

ClubService.addLicence = (licence) => {
    return fetch('/userLicence', 'POST', licence);
};

ClubService.getLicence = (id) => {
    return fetch(`/userLicence/${id}`);
};

ClubService.deleteLicence = (id) => {
    return fetch('/userLicence', 'DELETE', { id });
};

export default ClubService;
