import React, { useEffect, useState } from 'react';

// reactstrap components
import {
    Container, Row, Col, Card, Table, Button, Modal,
} from 'reactstrap';

// core components
import Header from '../../components/Headers/UserHeader';
import CertifModal from './CertifModal';

import CertificationService from '../../services/api/certifications';

const Certif = ({ history }) => {
    const [certifications, setCertifications] = useState([]);
    const [selected, setSelected] = useState(null);

    const update = () => {
        CertificationService.get().then(({ data }) => {
            setCertifications(data);
        });
    };

    const displayRows = () => {
        return certifications.map((item, key) => {
            const isClub = item.user.fk_type_id === 2;
            const avatar = isClub ? item.club?.logo : item.user.avatar;

            return (
                <tr key={`row-${key}`}>
                    <td>
                        <a
                            className="ml-5"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                        >
                            <img
                                className="avatar rounded-circle"
                                src={
                                    avatar
                                    || require('../../assets/img/default_avatar.jpg')
                                }
                            />
                        </a>
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                        {item.user.first_name} {item.user.last_name}
                    </td>
                    {isClub && (
                        <td>
                            Club{' '}
                            <a href="#" onClick={(e) => e.preventDefault()}>
                                ({item.club.name})
                            </a>
                        </td>
                    )}
                    {!isClub
                        && <td>
                            Joueur
                        </td>
                    }
                    <td>
                        <Button
                            color="primary"
                            size="sm"
                            type="button"
                            onClick={() => setSelected(item)}
                        >
                            Vérifier
                        </Button>
                        <Button
                            color="success"
                            size="sm"
                            type="button"
                            onClick={(e) => {e.preventDefault(); history.push(`/admin/profile/${item.user.id}`)}}
                        >
                            Voir Profil
                        </Button>
                    </td>
                </tr>
            );
        });
    };

    useEffect(() => {
        update();
    }, []);

    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                <Card
                    className="bg-secondary shadow border-0"
                    style={{ marginTop: 80 }}
                >
                    <Row>
                        <Col xs={12}>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Utilisateur</th>
                                        <th scope="col">Type (nom de club)</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>{displayRows()}</tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card>
            </Container>
            <Modal
                isOpen={!!selected}
                className="modal-dialog-centered"
                size="lg"
            >
                <CertifModal
                    certif={selected}
                    close={() => setSelected(null)}
                    update={update}
                />
            </Modal>
        </>
    );
};

export default Certif;
