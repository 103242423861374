import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components
import AuthNavbar from '../components/Navbars/AuthNavbar';
import AuthFooter from '../components/Footers/AuthFooter';

import routes from '../routes';

class Auth extends React.Component {
    componentDidMount() {
        document.body.classList.add('bg-default');
    }

    componentWillUnmount() {
        document.body.classList.remove('bg-default');
    }

    getRoutes = (routes) => routes.map((prop, key) => {
        if (prop.layout === '/auth') {
            return (
                <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                />
            );
        }
        return null;
    });

    render() {
        // console.log(this.props);
        return (
            <>
                <div className="main-content">
                    <AuthNavbar />
                    <div className="header bg-gradient-secondary py-4 py-lg-8">
                        <Container>
                            <div className="header-body text-center mb-7">
                                <Row className="justify-content-center">
                                    <Col lg="5" md="6">
                                        <h1 style={{ color: '#172B4D', marginTop: 50 }}>Admin.</h1>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-default"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </div>
                    {/* Page content */}
                    <Container className="mt--8 pb-5">
                        <Row className="justify-content-center">
                            <Switch>
                                {this.getRoutes(routes)}
                                <Redirect from="*" to="/auth/login" />
                            </Switch>
                        </Row>
                    </Container>
                </div>
                <AuthFooter />
            </>
        );
    }
}

export default Auth;
