import React, { useState } from 'react';
import {
    Card,
    CardBody,
    Row,
    Col,
    Container,
    FormGroup,
    Input,
    Button,
} from 'reactstrap';
import NotifService from 'services/api/notification';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Header from '../../components/Headers/UserHeader';


const limits = {
    title: 47,
    body: 80,
};

const Notification = () => {
    const [options, setOptions] = useState({
        title: {
            fr: '',
            en: '',
            es: '',
        },
        body: {
            fr: '',
            en: '',
            es: '',
        },
        target: 1,
    });

    const updateText = (name, lang, value) => {
        if (value.length > limits[name]) return;

        setOptions((old) => {
            const newObj = { ...old };
            newObj[name][lang] = value;
            return newObj;
        });
    };

    const handleSelect = (e) => {
        const value = e.target.value;
        setOptions((old) => {
            const newObj = { ...old };
            newObj.target = parseInt(value, 10);
            return newObj;
        })
    }

    const send = (e) => {
        e.preventDefault();
        NotifService.send(options);
        NotificationManager.success('Notification envoyée !', '', 10000);
        setOptions({
            title: {
                fr: '',
                en: '',
                es: '',
            },
            body: {
                fr: '',
                en: '',
                es: '',
            },
            target: 1,
        });
    }

    return (
        <>
            <Header color="orange" />
            <NotificationContainer/>
            <Container className="mt--8" fluid>
                <Card
                    className="bg-secondary shadow border-0"
                    style={{ marginTop: 80 }}
                >
                    <CardBody className="px-lg-5 py-lg-5">
                        <Row>
                            <Col xs="12" sm="8">
                                <h1><i className="ni text-orange ni-send"/> Envoyer une notification</h1>
                                <a href="https://getemoji.com/" target="_blank">Ajouter des emojis</a>
                                <hr className="my-3" />
                                <h3>Titre <small>({limits.title} caractères maxi.)</small></h3>
                                <FormGroup>
                                    <Input
                                        className="mt-1"
                                        id="title-fr"
                                        placeholder="FR"
                                        type="text"
                                        onChange={(e) => updateText('title', 'fr', e.target.value)}
                                        value={options.title.fr}
                                    />
                                    <small className={limits.title - options.title.fr.length <= 5 ? 'text-red' : 'text-primary'}>{limits.title - options.title.fr.length} caractères restants</small>
                                    <Input
                                        className="mt-2"
                                        id="title-en"
                                        placeholder="EN"
                                        type="text"
                                        onChange={(e) => updateText('title', 'en', e.target.value)}
                                        value={options.title.en}
                                    />
                                    <small className={limits.title - options.title.en.length <= 5 ? 'text-red' : 'text-primary'}>{limits.title - options.title.en.length} caractères restants</small>
                                    <Input
                                        id="title-es"
                                        className="mt-2"
                                        placeholder="ES"
                                        type="text"
                                        onChange={(e) => updateText('title', 'es', e.target.value)}
                                        value={options.title.es}
                                    />
                                    <small className={limits.title - options.title.es.length <= 5 ? 'text-red' : 'text-primary'}>{limits.title - options.title.es.length} caractères restants</small>
                                </FormGroup>
                                <h3>Message  <small>(80 caractères maxi.)</small></h3>
                                <FormGroup>
                                    <Input
                                        id="body-fr"
                                        className="mt-2"
                                        placeholder="FR"
                                        type="text"
                                        onChange={(e) => updateText('body', 'fr', e.target.value)}
                                        value={options.body.fr}
                                    />
                                    <small className={limits.body - options.body.fr.length <= 5 ? 'text-red' : 'text-primary'}>{limits.body - options.body.fr.length} caractères restants</small>
                                    <Input
                                        id="body-en"
                                        className="mt-2"
                                        placeholder="EN"
                                        type="text"
                                        onChange={(e) => updateText('body', 'en', e.target.value)}
                                        value={options.body.en}
                                    />
                                    <small className={limits.body - options.body.en.length <= 5 ? 'text-red' : 'text-primary'}>{limits.body - options.body.en.length} caractères restants</small>
                                    <Input
                                        id="body-es"
                                        className="mt-2"
                                        placeholder="ES"
                                        type="text"
                                        onChange={(e) => updateText('body', 'es', e.target.value)}
                                        value={options.body.es}
                                    />
                                    <small className={limits.body - options.body.es.length <= 5 ? 'text-red' : 'text-primary'}>{limits.body - options.body.es.length} caractères restants</small>
                                </FormGroup>
                                <h3>Cible</h3>
                                <Input
                                    type="select"
                                    onChange={handleSelect}
                                    value={options.target}
                                >
                                    <option value={1}>Joueurs</option>
                                    <option value={2}>Clubs</option>
                                </Input>
                                <div className="text-center">
                                    <Button
                                        className="mt-5 bg-orange text-white"
                                        disabled={!options.title.fr || !options.title.en || !options.title.es || !options.body.fr || !options.body.en || !options.body.es}
                                        onClick={send}
                                    >
                                        <i className="ni text-white ni-send"/>
                                        {" "}
                                        Envoyer
                                    </Button>
                                </div>
                            </Col>
                            <Col xs="12" sm="4">
                                <h3>Aperçu</h3>
                                <div style={{
                                    backgroundColor: '#fff',
                                    width: '90%',
                                    minHeight: 300,
                                    borderTopLeftRadius: 35,
                                    borderTopRightRadius: 35,
                                    borderWidth: 2,
                                    borderStyle: 'solid',
                                    borderColor: 'white',
                                    borderBottomColor: 'white',
                                    paddingTop: 15,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                }}>
                                    <div style={{
                                        backgroundColor: '#9E9E9E',
                                        minHeight: 285,
                                        borderTopLeftRadius: 25,
                                        borderTopRightRadius: 25,
                                        textAlign: 'center',
                                    }}>
                                        <div style={{
                                            backgroundColor: '#fff',
                                            width: '45%',
                                            height: 35,
                                            borderBottomLeftRadius: 15,
                                            borderBottomRightRadius: 15,
                                            display: 'inline-block',
                                            marginTop: -5,
                                        }}/>
                                        <div style={{
                                            backgroundColor: 'white',
                                            width: '90%',
                                            display: 'inline-block',
                                            borderRadius: 20,
                                            minHeight: 100,
                                            marginTop: 30,
                                            textAlign: 'left',
                                            padding: 15,
                                        }}>
                                            <p style={{
                                                fontFamily: 'Arial',
                                                fontWeight: 700,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'pre',
                                                fontSize: 18,
                                                color: 'black'
                                            }}>
                                                {options.title.fr || 'Titre de notification'}
                                            </p>
                                            <p style={{
                                                fontFamily: 'Arial',
                                                fontWeight: 500,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'pre',
                                                fontSize: 18,
                                                color: 'black',
                                                marginTop: -15,
                                                marginBottom: -15,
                                            }}>
                                                {options.body.fr || 'Message'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};

export default Notification;
