import types from './types';

const UserActions = {};

UserActions.setToken = (token) => ({
    type: types.SET_TOKEN,
    payload: token,
});

export default UserActions;
