import { fetch } from './api';

const CertificationService = {};

CertificationService.get = () => {
    return fetch('/certification');
};

CertificationService.certify = (id, action) => {
    return fetch('/certification/certify', 'POST', { id, action });
};

export default CertificationService;
